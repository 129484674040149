<template>
<v-col cols="12" class="py-0 bg-brilliant">
  
  <v-row v-if="showTopInfoBar && !$store.state.auth.isLogin" class="">
    <v-col v-if="false" cols="12" class="mx-auto text-center" style="background-color:rgb(63, 81, 181);">
      <v-toolbar elevation="0" color="transparent" class=" white--text">
        <v-spacer/>
        <v-chip class="mr-1" color="red" dark><b>Version beta : Òtomony est en phase de lancement</b> </v-chip>
        <v-spacer/>
        <v-btn outlined icon small color="white" @click="closeTopInfoBar()" class="ml-2"><v-icon small>mdi-close</v-icon></v-btn>
      </v-toolbar>
    </v-col>
    <v-col v-if="false" cols="12" class="mx-auto text-center" style="background-color:rgb(63, 81, 181);">
      <v-toolbar elevation="0" color="transparent" class=" white--text">
        <v-spacer/>
        <router-link  to="/register" style="text-decoration:none;color:white;">
        <span>Recevez <b class="px-1">30 òto</b> en créant votre compte gratuit<span class=""> dès maintenant</span> !</span>
        </router-link>
        <v-btn class="ml-4 d-none d-md-flex" outlined rounded color="white" dark 
               v-if="!$store.state.auth.isLogin"
               to="/register">
          <v-icon>mdi-account-plus</v-icon> Créer mon compte
        </v-btn>
        <v-spacer/>
        <v-btn outlined icon small color="white" @click="closeTopInfoBar()" class="ml-2"><v-icon small>mdi-close</v-icon></v-btn>
      </v-toolbar>
    </v-col>
  </v-row>
  
  <v-row class="home-banner-img">
    <v-col cols="11" sm="11" md="9" lg="7" class="mx-auto search-col" style="">
    <SearchBlock></SearchBlock>
    <h1 class=" text-center white--text " v-if="$vuetify.breakpoint.width > 600" style="font-size:23px;line-height:25px;">
        <!-- Parcourez les annonces, et dépensez vos Òto en toute simplicité ! -->
        Créez vite votre <span class="amber--text text--darken-1">compte gratuit</span> pour recevoir vos òto tous les jours !
      </h1>
    </v-col>
  </v-row>

  <v-row class="">
    <v-col cols="12" sm="11" md="10" lg="8" class="d-none mx-auto text-center">
       
      <router-link :to="!$store.state.auth.isLogin ? '/register' : '/market'">
      <v-img src="../assets/img/logo-oto.png" class="d-inline-block" height="80" width="80" 
            style="box-shadow: rgba(153, 108, 15, 0.47) 0px 0px 5px 1px; border-radius:100%;" 
            :style="'margin-top:20px;'" />
      </router-link>
      <h1 class="pt-5 amber--text text--darken-2" v-if="$vuetify.breakpoint.width > 600" style="font-size:35px;line-height:35px;">
        Parcourez les annonces,<br>et dépensez vos Òto en toute simplicité !
      </h1>
      <h1 v-else class="amber--text text--darken-2 pt-5" style="font-size:25px;line-height:25px;">
        Parcourez les annonces,<br>et dépensez vos Òto<br>en toute simplicité !
      </h1>
    </v-col>
  </v-row>


  <v-row>
    <v-col cols="12" sm="11" md="10" lg="7" class="mx-auto d-none">
      
      
      <template v-if="showTopInfoBar">
        <v-divider style="margin-bottom:30px;"/>
        <v-row class="font-main">
          <v-col cols="12" sm="4" md="4" lg="4" class="text-center ml-auto s-image">
            <v-img src="../assets/img/homepage/spaceship.webp" max-height="200" contain class="mt-5"/><br> 
          </v-col>
          <v-col cols="12" sm="8" md="7" lg="6" class="mr-auto">
            <h2 class="red--text text--darken-2 mb-3">
              <v-icon color="red darken-2">mdi-rocket-launch-outline</v-icon> 
              Phase de lancement activée !
            </h2>
            <v-alert v-if="showTopInfoBar" outlined elevation="0" color="red darken-2" class="text-">
              <v-icon color="red darken-2" x-small>mdi-circle</v-icon>
              Depuis le 14 mai 2022, les inscriptions sont ouvertes 
              afin de permettre aux premiers visiteurs de tester la plateforme.
              <br class="mb-2">
              <!-- <v-icon color="red darken-2" x-small>mdi-circle</v-icon>
              L'ensemble des données peuvent encore être réinitialisée 
              si besoin avant l'ouverture définitive de la plateforme.
              <br class="mb-2"> -->
              <v-icon color="red darken-2" x-small>mdi-circle</v-icon>
              Certaines annonces fictives ont été publiées à titre d'exemples, 
              pour vous aider à découvrir la plateforme.
              <br class="mb-2">
              <v-icon color="red darken-2" x-small>mdi-circle</v-icon>
              N'hésitez pas à créez votre compte gratuit pour recevoir vos 30 premiers Òto, 
              et publier vos propres annonces !
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" sm="11" md="10" lg="9" class="mx-auto">

      <!-- <v-divider style="margin-bottom:10px;"/> -->
      <v-col class="text-center mt-5 mb-2">
          <v-icon small>mdi-chevron-down</v-icon> 
          <b>Les dernières annonces</b>
      </v-col>

      <v-row>
        <template v-for="(offer, o) in $store.state.market.offers">
          <v-col cols="6" sm="6" md="4" lg="3" class="px-1 py-1" :key="o">
              <OfferCardSM :offer="offer" :key="offer._id"></OfferCardSM>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>


  <v-row>
    <v-col cols="12" sm="11" md="10" lg="9" class="mx-auto col-white">
      <v-divider style="margin:50px 0 0 0;"/>
    </v-col>
  </v-row>

  <v-row style="padding-bottom:70px;">
    <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto section text-center mt-5">
        <h2 class="s-main-title font-main pb-1 pt-5 amber--text text--darken-4">
          Économisez vos €uros, payez Òto !
        </h2>
    </v-col>

    <v-col cols="12" class="mx-auto d-sm-none text-center mt-5 ">
      <iframe width="350" height="190" src="https://www.youtube.com/embed/xxEN1TzSpj0" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
    </v-col>
    <v-col cols="12" class="mx-auto d-none d-sm-inline d-md-none text-center mt-5 ">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xxEN1TzSpj0" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
    </v-col>
    <v-col cols="12" class="mx-auto d-none d-md-inline text-center mt-5 ">
      <iframe width="790" height="450" src="https://www.youtube.com/embed/xxEN1TzSpj0" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
    </v-col>
  </v-row>


  <v-row style="background-color:rgb(58, 79, 180);" class="section">
    <v-col cols="12" sm="12" md="7" lg="7" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main">
        <span class="amber--text text--darken-4">
        Òtomony</span>
        <span class="indigo--text text--lighten-4">
           c'est la monnaie virtuelle que vous recevez
        </span>
        chaque jour,
        <span class="indigo--text text--lighten-4">
          dès votre inscription sur la plateforme.
        </span>
        <div class="s-main-text font-main pb-0">
            Vous recevez quotidiennement entre 0 et 1 òto,<br class="d-none d-md-inline"> en fonction du solde de votre compte courrant.
        </div>
        <div class="s-main-text font-main pb-0">
            Cette monnaie, créée par notre système, peut être utilisée pour 
            <b>acheter des biens d'occasion</b> ou <b>des services</b>,
            via notre système de <b>petites annonces</b>.
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4" lg="4" class="text-center mx-auto s-image">
      <v-img src="../assets/img/homepage/wallet3.png" max-height="450" contain class=""/>
    </v-col>
  </v-row>


  <v-row style="background-color:#FFF;" class="section ">
    <v-col cols="12" sm="4" md="5" lg="4" class="px-0 ml-auto d-none d-sm-flex">
      <v-img src="../assets/img/homepage/flat-user-icon2.jpg" class="" contain />
      <!-- <br>
      <a href='https://fr.freepik.com/vecteurs/personnes'>
      Personnes vecteur créé par freepik - fr.freepik.com
      </a> -->

    </v-col>
    <v-col cols="12" sm="8" md="7" lg="7" class="text-left indigo--text">
      <h2 class="s-main-title font-main">
        Une monnaie équitable,<br>qui profite à tous.
        <div class="s-main-text font-main pb-0">
            Les bénéfices de la création monétaire sont répartis équitablement entre tous les utilisateurs de notre monnaie : 
            <b>vous recevez tous, chaque jour, la même quantité de <span class="d-inline-block">monnaie !</span></b>
        </div>
      </h2>
    </v-col>
    <v-col cols="12" class="ml-auto s-image d-sm-none">
      <v-img src="../assets/img/homepage/flat-user-icon2.jpg" class="" contain />
    </v-col>
  </v-row>


  <v-row style="background-color:#2e1d3b">
    <v-col cols="12" sm="8" md="8" lg="8" class="section text-left purple--text text--lighten-4">
      <div class="s-main-title font-main pb-5 mb-5">
        <span class="white--text">
          Votre compte se remplit 
          tout seul, tous les jours, et sans rien faire !
        </span>
        <br><br>
        Jusqu'à atteindre<br>le plafond de <span class="white--text">30.4375 òto</span>
        <div class="s-main-text font-main pb-0 white--text">
            Soit, le nombre moyen de jours, dans chaque mois de l'année :
            <br>
            <b>365.25 / 12 = 30.4375</b>
        </div>
        
        <br>
        <v-btn large outlined dark style="font-size:16px !important;" to="/mony">
          Découvrir le modèle mathématique <v-icon class="ml-2">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>

      </div>
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4" class="ml-auto text-center" style=" margin-top:50px;">
      <img src="../assets/img/homepage/univers.png" max-height="400"
             style="border-radius:50%;"  class="rotate"/>
    </v-col>
  </v-row>


  <v-row class="section mt-0" style="background-color: #2e1d3b;">
    <v-col cols="12" sm="11" md="10" lg="11" class="text-left mx-auto px-0 px-sm-5 py-0 my-0 py-sm-5 my-sm-5">
      <iframe src="https://slides.com/otomony/otomony/embed" style="box-shadow: 0px 0px 20px -8px;margin-bottom:-12px;"
              width="100%" height="600" title="Présentation du modèle mathématique de la crypto-monnaie Òtomony" 
              scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </v-col>
  </v-row>


  <v-row style="background-color:#FFF;">
    <v-col cols="12" sm="11" md="10" lg="6" class="section mr-auto text-left indigo--text">
      <div class="s-main-title font-main  pr-5">
        Quelle est<br>la valeur d'1 òto ?<br><br>
        <small><b>1 òto ≃ 10 €uros</b></small>
        <div class="s-main-text font-main pb-0">
            Pour fixer les prix des annonces, le taux de conversion est fixé à 
            <span class="d-inline-block">environ 10€ pour 1 òto.</span>
            <br><br>
            <b>Ce qui signifie que :</b><br><br><br class="d-md-none">
            <v-icon x-small color="indigo">mdi-circle</v-icon> Vous recevez l'équivalent d'environ <b>300€</b> de pouvoir d'achat dès votre inscription.<br><br class="d-md-">
            <v-icon x-small color="indigo">mdi-circle</v-icon> Vous gagnez <b>entre 1 et 10€</b> par jour, en fonction de la quantité de monnaie déjà présente sur votre compte,
            jusqu'à atteindre <b>la moyenne de 30.4375 òto</b> sur votre compte courant - soit l'équivalent de 304.375€.
            <br>
            <br><br>
            <div class="text-right mr-5">
              <v-btn outlined color="indigo" to="/faq" class="mr-5" large>
                En savoir plus
                <v-icon class="ml-2">mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="6" class="section mr-auto text-right text-lg-left red--text text--darken-3 px-1">
      <div class="s-main-title font-main pl-lg-0 pr-lg-5">
        <div class="s-main-text font-main pb-0 d-none d-sm-inline" style="font-size:16px;">
          <v-chip dark color="red lighten-1" large class="px-5 my-3">
            <v-icon color="white" large class="mr-5">mdi-white-balance-sunny</v-icon>
            Otomony n'est pas une monnaie spéculative.<br>
            Le taux de conversion est fixe.
          </v-chip>
          <br>
          <v-chip dark color="red lighten-2" large class="px-5 my-3 ml-4" >
            <v-icon color="white" large class="mr-3">mdi-currency-eur-off</v-icon>
            Il n'est pas possible d'acheter des Òto, ni de les revendre en ligne.<br>
            Les Òto ne sont pas convertibles en €uro, ni aucune autre monnaie.
          </v-chip>
          <br>
          <v-chip dark color="green darken-1" large class="px-5 my-3" >
            <v-icon color="white" large class="mr-5">mdi-handshake</v-icon>
            Le but est d'échanger vos òto contre des biens et des services réels. <br>
            Pas de les accumuler, ni de spéculer sur leur valeur.
          </v-chip>
        </div>
        <div class="s-main-text font-main pb-0 d-sm-none text-left" style="font-size:16px; border-top:1px solid grey;">
          <span class="">
            Òtomony n'est pas une monnaie spéculative.<br>
            Le taux de conversion est fixe.
          </span>
          <br><br>
          <span class="">
            Il n'est pas possible d'acheter des Òto, ni de les revendre en ligne. 
            Les Òto ne sont pas convertibles en €uro, ni aucune autre monnaie.
          </span>
          <br><br>
          <span class="green--text" >
            Le but est d'échanger vos òto contre des biens et des services réels. 
            Pas de les accumuler, ni de spéculer sur leur valeur.
          </span>
          <br><br>
          <div class="text-right mr-5">
            <v-btn outlined color="indigo" to="/faq" class="mr-5" large>
              En savoir plus
              <v-icon class="ml-2">mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>


  <v-row style="padding-bottom:70px;" class="b">
    <v-col cols="12" sm="11" md="10" lg="8" class="mx-auto section text-center">
        <div class="s-main-title font-main pb-1 px-0 indigo--text">
          Prêt pour la démo ?
        </div>
        <div class="mb-5 pa-4">
          <span class="font-main">Pour mieux comprendre comment fonctionne la monnaie, suivez la démo !</span>
        </div>
        <DemoWallet/>
    </v-col>
  </v-row>


  <v-row style="background-color:rgb(58, 79, 180);" class="section">

    <v-col cols="12" sm="12" md="8" lg="8" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main" id="section-walletDeposit">
        Les comptes de dépots permettent de stocker votre monnaie, sans perdre sa valeur.
        <div class="s-main-text font-main pb-0 mt-5">
            <b>Chaque utilisateur dispose de 2 types de compte :</b><br><br>
            <b><v-icon color="white">mdi-wallet</v-icon> Un compte courant</b><br>
            - Qui reçoit la monnaie créée par le système<br>
            - Pour tendre vers la moyenne de 30.4375 òto<br>
            - Selon la règle suivante : plus on a, moins on reçoit
            <br><br>
            <b><v-icon color="white">mdi-wallet</v-icon> Un compte de dépot</b><br> 
            - Qui stock votre monnaie, à valeur fixe<br>
            - Dans la limite de 365.25 òto (≃3652.5€)<br>
            - Soit environ 1 òto par jour pendant 1 an<br>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4" lg="3" class="text-center mr-auto s-image">
      <v-img src="../assets/img/homepage/coffre.jpg" max-height="400" contain class=""/>
    </v-col>
  </v-row>

  <!-- <v-row style="background-color:#FFF; padding-bottom:70px;" class="b">
    <v-col cols="12" sm="11" md="10" lg="10" class="mx-auto section text-center">
        <div class="s-main-title font-main pb-1 px-0 indigo--text">
          Chaque utilisateur dispose de<br>2 types de compte :
        </div>
    </v-col>
    <v-col cols="12" sm="11" md="6" lg="4" class="mb-5 ml-auto section text-center">
      <v-card color="indigo" elevation="0" dark class="px-3 pt-4" min-height="230">
        <div class="s-main-subtitle font-main pb-1 px-0 --text">
          <v-icon color="">mdi-credit-card-clock</v-icon> Un compte courant
        </div>
        Qui reçoit la monnaie créée par le système,
        et tend toujours vers la moyenne de 30.4375 òto,
        selon la règle suivante : plus on a, moins on reçoit.
        <br><br>
      </v-card>
    </v-col>
    <v-col cols="12" sm="11" md="6" lg="4" class="mb-5 mr-auto section text-center">
      <v-card color="indigo" elevation="0" dark class="px-3 pt-4" min-height="230">
        <div class="s-main-subtitle font-main pb-1 px-0 --text">
          <v-icon color="">mdi-credit-card-settings</v-icon> Un compte de dépot
        </div>
        Qui stock votre monnaie, à valeur fixe,
        dans la limite de 365.25 òto (≃3652.5€),
        soit environ 1 òto par jour pendant 1 an.
        <br><br>
      </v-card>
    </v-col>
    <v-col cols="12" sm="11" md="10" lg="10" class="mt-5 mx-auto section text-center">
        <DemoWalletCarousel/>
    </v-col>
  </v-row> -->


  <PlanBlock/>


  <v-row style="background-color:rgb(202, 74, 74);" class="section">

    <v-col cols="12" sm="12" md="8" lg="8" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main" id="section-walletDeposit">
        Participez au décollage <span class="d-inline-block">d'Òtomony !</span>
        <br>
        <small><small><i>C'est très simple :</i></small></small>
        <div class="s-main-text font-main pb-0 mt-5">
            <b>1 - Créez votre <router-link class="amber--text text--darken-2" to="/register/premium">compte gratuit</router-link> dès maintenant</b> ! (pas de CB)<br>
            <b>2 - Publiez votre première annonce, pour activer votre compte</b><br>
            <b>3 - Et partagez Òtomony sur les réseaux !</b><br>
        </div>


        <div class="s-main-text font-main pb-0 text-">
            <v-btn href="https://www.facebook.com/%C3%92tomonyfr-112338428135478" target="_blank"
                   x-large color="white" rounded outlined class="pl-2 ma-1">
              <v-icon x-large>mdi-facebook</v-icon> Facebook
            </v-btn>
            <v-btn href="https://www.instagram.com/tristan4_otomony/" target="_blank"
                   x-large color="white" rounded outlined class="pl-2 ma-1">
              <v-icon class="ml-3" large>mdi-instagram</v-icon> Instagram
            </v-btn>
            <v-btn href="https://discord.gg/acCCWSEKUd" target="_blank"
                   x-large color="white" rounded outlined class="pl-2 ma-1">
              <v-icon class="ml-3" large>mdi-discord</v-icon> Discord
            </v-btn>
            <v-btn href="https://www.youtube.com/channel/UC6_QZv0u8hRpPGAIqB4r58Q/videos" target="_blank"
                   x-large color="white" rounded outlined class="pl-2 ma-1">
              <v-icon class="ml-3" large>mdi-youtube</v-icon> Youtube
            </v-btn>
        </div>

        <div class="s-main-text font-main py-0">
            <br>
            Plus il y aura de membres, 
            <b class="d-inline-block">plus nous serons riches de nos échanges</b> !

            <br>

            <br><br>
            <v-alert color="white" outlined>
              <h2>Investissez sans risque !</h2><br>
              Bénéficier d'un compte <b>PREMIUM</b>, c'est réaliser <b class="d-inline-block">un investissement de 0€</b>, 
              qui vous rapportera l'équivalent de <b>10€</b> de pouvoir d'achat, <b>tous les jours</b>,
              à dépenser librement parmis les annonces publiées sur la plateforme.

              <br>
              <br>
              <b>Même si le nombre d'annonces est encore faible pour l'instant,
              vous avez tout intérêt à vous inscrire le plus tôt possible,
              pour commencer à gagner des òto tous les jours !</b> 
              Ensuite, plus le nombre d'utilisateur sera important, plus il y aura d'annonces, 
              et plus les òto que vous aurez accumulés pourront vous servir !

            </v-alert>
            
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4" lg="3" class="text-center mx-auto s-image">
      <v-img src="../assets/img/homepage/spaceship.webp" max-height="500" contain class=""/>
      <br>
      <v-btn block color="blue"  x-large 
           elevation="0" :to="'/register/premium'" class="white--text" 
           :disabled="($store.state.app.nbUsersTotal > $store.state.app.limitForFreePlan)">
           <v-icon class="mr-1">mdi-account-plus</v-icon>
            Je m'inscris maintenant !
      </v-btn>
      <br>
    </v-col>
  </v-row>


  <v-row>
    <v-col class="pa-0">
      <Footer v-if="showFooter"/>
    </v-col>
  </v-row>

  <OfferDialog></OfferDialog>
</v-col>
</template>

<style>
</style>

<script>

import '@/assets/css/home.css';

import Footer from '@/components/main-layout/Footer.vue'
import Login from '@/components/main-layout/Login.vue'

import SearchBlock from '@/components/home/SearchBlock.vue'
import DemoWallet from '@/components/home/DemoWallet.vue'
import DemoWalletCarousel from '@/components/home/DemoWalletCarousel.vue'
import PlanBlock from '@/components/home/PlanBlock.vue'
import OfferDialog from '@/components/offer/OfferDialog.vue'
import OfferCardSM from '@/components/offer/OfferCardSM.vue'

export default {
  name: 'home',
  components: {
    Footer, Login, SearchBlock, OfferCardSM, DemoWallet, 
    DemoWalletCarousel, 
    OfferDialog, PlanBlock
  },
  data: () => ({
    showTopInfoBar: true,
    showFooter: true,
    plans: []
  }),
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/home')
    this.$store.dispatch('app/fetchNbUsersTotal')
    this.$store.dispatch('app/fetchEntities', { entityType: 'plan', 
                                                query : { "isActive": true },
                                                sort: { position: 1 } 
                                              })
  },
  methods: {
    closeTopInfoBar(){
      this.showTopInfoBar = false
    },
  },
}
</script>
